import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Header = () => (
  <div class={style.header}>
    <a href="#inicio" class={style.logo}>
      <img
        src="../../assets/CELogo.svg"
        alt="Logo Corporativo"
        height="69"
        width="70"
      />
    </a>
    <div class={style.addressContainer}>
      <a
        class={style.addressItem}
        href="https://www.corpotrabajo.com.mx/"
        target="_blank"
      >
        <img
          src="../../assets/bolsadetrabajo.png"
          alt="Logo Corporativo"
          height="20"
          width="20"
        />
        <h5 class={style.address}>Bolsa de trabajo</h5>
      </a>
      <div class={style.addressItem}>
        <img
          src="../../assets/localizacion.png"
          alt="Logo Corporativo"
          height="20"
          width="20"
        />
        <h5 class={style.address}>
          Av. Adolfo López Mateos Sur 7000, La Rioja, 45640 Los Gavilanes, Jal.
        </h5>
      </div>
    </div>
  </div>
);

export default Header;
