import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Header = () => (
  <header
    id="inicio"
    class={`${style.header} col-lg-6 col-md-6 col-sm-12 col-xs-12`}
  >
    <a
      href="#inicio"
      class={`${style.logo} col-lg-6 col-md-6 col-sm-12 col-xs-12`}
    >
      <img
        src="../../assets/CELogo.svg"
        alt="Logo Corporativo"
        height="70"
        width="70"
      />
    </a>
    <nav class={`${style.NavMenu} col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
      <a href="#conocenos">
        <h4>Conócenos</h4>
      </a>
      <div class={style.borderleft}>|</div>
      <a href="#unidades-de-negocio">
        <h4>Unidades de Negocio</h4>
      </a>
    </nav>
  </header>
);

export default Header;
